import styled from "styled-components";

export const UseInfoItem = styled.li`
  display: grid;
  padding: 20px 0;
  grid-template-columns: 70px auto 70px;
  gap: 4px;

  & + li {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

export const UseInfoName = styled.div`
  padding-left: 6px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
`;

export const UseInfoTxtBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const UseInfoTxtNumber = styled.div`
  display: flex;
  color: black;
  font-size: 1.5rem;
  font-weight: 600;
  align-item: center;
  gap: 6px;

  span {
    width: 44px;
    color: ${(props) => props.theme.colors.mainTxt};
    font-size: 1.2rem;
    font-weight: 500;
  }
`;
