import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "styled-components";
import Core from "./components/Core";
import Home from "./pages/Home/Home";
import Eval from "./pages/Eval/eval";
import Result from "./pages/Result/Result";
import Notice from "./pages/Notice/Notice";
import { theme } from "./styles/theme";
import UseInfo from "./pages/UseInfo/UseInfo";
import QnA from "./pages/QnA/QnA";
import Product from "./pages/Product/Product";
console.log("TEST");console.log("TEST");
function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <BrowserRouter>
            <Core>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/eval-health" element={<Eval />} />
                <Route path="/notice" element={<Notice />} />
                <Route path="/result" element={<Result />} />
                <Route path="/useinfo" element={<UseInfo />} />
                <Route path="/qna" element={<QnA />} />
                <Route path="/product" element={<Product />} />
                {/* <Route path="/about" element={<About />} /> */}
                {/* <Route path="*" element={<NotFound />} /> */}
                <Route path="*" element={<Home />}></Route>
              </Routes>
            </Core>
          </BrowserRouter>
        </RecoilRoot>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retry: 3,
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
});

export default App;
