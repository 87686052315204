import { ProductItem } from "../../atoms/ProductItem";
import * as S from "./productUserList.style";

interface ProductUserListProps {
  productUsers: any;
  cancelProduct: (id: number) => void;
}

export const ProductUserList = ({ productUsers, cancelProduct }: ProductUserListProps) => {
  return (
    <>
      <S.ProductUserList>
        {productUsers &&
          productUsers.map((productUser: any) => (
            <ProductItem
              key={productUser?.id}
              productUser={productUser}
              cancelProduct={cancelProduct}
            />
          ))}
      </S.ProductUserList>
    </>
  );
};
