import { ReactComponent as IconMapPin } from "../../../styles/assets/svg/icon-mapPin.svg";
import { Button } from "../Button";
import * as S from "./useInfoItem.style";

interface ProductItemProps {
  productUser: any;
  cancelProduct: (id: number) => void;
}

export const ProductItem = ({
  productUser,
  cancelProduct,
}: ProductItemProps) => {
  const formatPrice = (value?: number) => {
    if (!value) {
      return "0";
    }

    return value.toLocaleString();
  };
  return (
    <S.UseInfoItem>
      <S.UseInfoName>{productUser?.productName}</S.UseInfoName>

      <S.UseInfoTxtBox>
        <S.UseInfoTxtNumber>
          <span>개수</span>
          {productUser?.quantity} 개
        </S.UseInfoTxtNumber>
        <S.UseInfoTxtNumber>
          <span>총 금액</span>
          {formatPrice(productUser?.price * productUser?.quantity)} 토큰
        </S.UseInfoTxtNumber>
      </S.UseInfoTxtBox>
      {productUser?.isShipped === 0 && (
        <Button
          width="70px"
          height={38}
          color="subTxt"
          fontSize={1.4}
          directColumn={true}
          label="신청취소"
          onClick={() => cancelProduct(productUser?.id)}
        />
      )}
    </S.UseInfoItem>
  );
};
