import { useMutation, useQuery } from "@tanstack/react-query";
import { PRODUCT_LIST, PRODUCT_USER_LIST } from "../../common/api-codes";
import { request } from "../../lib/api";
import { Button } from "../../components/atoms/Button";
import { ProductUserList } from "../../components/molecules/ProductUserList";
import * as S from "../../components/molecules/Modal/modal.style";

interface ProductModalProps {
  onClose: () => void;
}
export const ProductModal = ({ onClose }: ProductModalProps) => {
  const {
    data: productUserList,
    isLoading,
    refetch,
  } = useQuery([PRODUCT_USER_LIST], () =>
    request<{ status: number; data: any[] }>({
      method: "GET",
      url: `api/v1/product/user/id`,
    }).then((res) => {
      return res.data ?? [];
    })
  );

  const { mutateAsync: cancelProductUser } = useMutation({
    mutationFn: (id: number) => {
      return request({
        method: "delete",
        url: `api/v1/product/user/${id}`,
      });
    },
    onSuccess: (res) => {
      alert("취소가 완료되었습니다.");
      refetch();
    },
    onError: (e: { message: number; error: string }) => {
      alert(e?.error || "서버요청 에러");
    },
  });

  const cancelProduct = async (id: number) => {
    const isConfirmed = window.confirm("취소하시겠습니까?");

    if (isConfirmed) {
      await cancelProductUser(id);
    }
  };

  return (
    <S.ModalBG>
      <S.ModalBox size="lg">
        <S.ListBox>
          <S.ModalHeader>
            <S.ModalTitle>상품 신청 목록</S.ModalTitle>
          </S.ModalHeader>
          <ProductUserList
            productUsers={productUserList}
            cancelProduct={cancelProduct}
          />
        </S.ListBox>
        <Button
          width="100%"
          height={46}
          color="primary"
          fontSize={1.6}
          directColumn={false}
          label="닫기"
          onClick={onClose}
        />
      </S.ModalBox>
    </S.ModalBG>
  );
};
