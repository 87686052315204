import styled from "styled-components";

interface ModalProps {
  size?: string;
}

export const ModalBG = styled.div`
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.black}a5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ModalBox = styled.div<ModalProps>`
  overflow: hidden;
  display: grid;
  width: ${(props) =>
    props.size === "md" ? "76vw" : props.size === "lg" && "calc(100vw - 32px)"};
  max-height: 90vh;
  padding: 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  grid-template-rows: 1fr 46px;
  gap: 20px;
`;

export const ModalHeader = styled.div`
  display: -webkit-box;
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.secondary};
  font-size: 2rem;
  font-weight: 700;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 2rem;

  button {
    width: 30px;
    height: 30px;
    background: none;
    cursor: pointer;
  }
`;

export const ModalTitle = styled.p`
  .visible {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 2rem;
    font-weight: 700;
    visibility: visible;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding: 20px;
  font-size: 1.5rem;
  font-weight: 500;
  white-space: pre-line;
  max-height: 400px;
  overflow-y: auto;
`;

export const ListBox = styled.div`
  display: flex;
  max-height: 70vh;
  flex-direction: column;
`;
