import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { request } from "../../lib/api";
import { OWNED_COIN, PRODUCT_LIST } from "../../common/api-codes";
import { LoadingSection } from "../../components/organisms/LoadingSection";
import { ProductTemplate } from "../../components/templates/ProductTemplate";
import { ProductItem } from "./ProductItem";
import { ProductModal } from "./ProductModal";
import { ReactComponent as IconWallet } from "../../styles/assets/svg/icon-wallet.svg";

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px 20px 30px;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  color: white;
  align-items: center;
`;

const QATitle = styled.h2`
  display: flex;
  width: 100%;
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
  align-items: center;
  gap: 4px;
`;

const QACurrentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    color: white;
  }
`;

const QACurrent = styled.div`
  display: flex;
  padding: 8px 16px;
  color: black;
  font-size: 2.2rem;
  font-weight: 900;
  background: #ffffffcc;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      color: #00000099;
      font-size: 1.8rem;
      font-weight: 600;
    }
  }
`;

const CurrentTitle = styled.div`
  display: flex;
  color: #000000cc;
  font-size: 1.5rem;
  font-weight: bold;
  align-items: center;
  gap: 8px;

  svg {
    path {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

const Button = styled.button`
  height: 36px;
  padding: 0 14px;
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.4rem;
  font-weight: bold;
  text-wrap: nowrap;
  border: none;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
`;

const QnAList = styled.div`
  overflow: auto;
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
`;

export default function Product() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: productList, isLoading } = useQuery([PRODUCT_LIST], () =>
    request<{ status: number; data: any[] }>({
      method: "GET",
      url: `api/v1/product`,
    }).then((res) => {
      return res.data ?? [];
    })
  );

  const { data: ownedCoin, refetch: ownedCoinnRefetch } = useQuery([OWNED_COIN], () =>
    request<{ ownedCoin: number }>({
      method: "GET",
      url: `api/v1/coin/ownedCoin`,
    }).then((res) => res.ownedCoin ?? 0)
  );

  const formatPrice = (value?: number) => {
    if (!value) {
      return "0";
    }

    return value.toLocaleString();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      {isModalOpen && <ProductModal onClose={handleModalClose} />}
      <ProductTemplate>
        <>
          <HeaderContainer>
            <TitleContainer>
              <QATitle>
                상품 신청
                {/* <Count>1 개</Count> */}
              </QATitle>

              <Button onClick={handleModalOpen}>신청 목록 보기</Button>
            </TitleContainer>

            <QACurrentBox>
              <QACurrent>
                <CurrentTitle>
                  <IconWallet width={20} height={20} />
                  나의 보유토큰
                </CurrentTitle>
                <span>
                  {formatPrice(ownedCoin)} <span>토큰</span>
                </span>
              </QACurrent>
              <p>원하시는 상품의 수량을 입력하신뒤 신청 버튼을 눌러주세요.</p>
            </QACurrentBox>
          </HeaderContainer>

          {isLoading ? (
            <LoadingSection />
          ) : (
            <QnAList>
              {productList?.map((product) => {
                return <ProductItem product={product} ownedCoin={ownedCoin} />;
              })}
            </QnAList>
          )}
        </>
      </ProductTemplate>
    </>
  );
}
