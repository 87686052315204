import * as S from "./tokenWallet.style";
import { ReactComponent as IconWallet } from "../../../styles/assets/svg/icon-wallet.svg";
import { ReactComponent as IconArrowSm } from "../../../styles/assets/svg/icon-arrow-sm.svg";

interface TokenWalletProps {
  ownedCoin: number | string;
}

export const TokenWallet = ({ ownedCoin }: TokenWalletProps) => {
  const handleMoveToCoinHistory = () => window.android.moveToCoinHistory();

  return (
    <S.TokenWalletBox>
      <S.TitBox onClick={handleMoveToCoinHistory}>
        <S.TitSpan>
          <IconWallet width={20} height={20} />내 보유 토큰
        </S.TitSpan>
        <IconArrowSm width={10} height={16} />
      </S.TitBox>

      <S.CountBox>
        {ownedCoin.toLocaleString()}
        <S.CountUnit>INS</S.CountUnit>
      </S.CountBox>
    </S.TokenWalletBox>
  );
};
