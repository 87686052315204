import styled from "styled-components";
import { Product } from "../../common/typs";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { request } from "../../lib/api";

const QnAItem = styled.div`
  display: flex;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  flex-direction: column;
  gap: 20px;
`;

const QnAItemContent = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.unitTxt};
  font-size: 1.2rem;
  gap: 4px;
`;

const QnAItemTitle = styled.div`
  display: flex;
  width: 100%;
  color: black;
  font-size: 1.8rem;
  font-weight: 600;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
`;

const QnAItemPrice = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.unitTxt};
  font-size: 1.6rem;
  font-weight: 600;
  align-items: center;
  gap: 3px;
`;

const AnswerContent = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.8);
  flex-direction: column;
  gap: 4px;
`;

const SubmitBox = styled.div`
  display: flex;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  flex-direction: column;
  gap: 20px;
`;

const SubmitContent = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.4rem;
  align-items: center;
  justify-content: space-between;
`;

const SubmitUnit = styled.div`
  display: flex;
  font-size: 1.8rem;
  font-weight: 800;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: no-wrap;
  gap: 4px;

  span {
    font-size: 1.6rem;
    font-weight: 600;
    text-wrap: nowrap;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 10px;
  color: #333333;
  font-size: 1.5rem;
  text-align: right;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  outline: none;

  &:focus {
    border-color: #e07010;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px 16px;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 700;
  background: white;
  border: 0.8px solid ${(props) => props.theme.colors.primary};
  border-radius: 8px;
  box-shadow: 0px 2px 10px -4px rgba(0, 0, 0, 0.06);
  font-size: 1.6rem;
  cursor: pointer;

  &:active {
    background: ${(props) => props.theme.colors.primary}0f;
    box-shadow: none;
  }
`;

interface ProductItemProps {
  product: Product;
  ownedCoin?: number;
}
export const ProductItem = ({ product, ownedCoin }: ProductItemProps) => {
  const [count, setCount] = useState(0);
  const formatPrice = (value?: number) => {
    if (!value) {
      return "0";
    }

    return value.toLocaleString();
  };

  const { mutateAsync: createProductUser, isLoading } = useMutation({
    mutationFn: (data: any) => {
      return request({
        method: "POST",
        url: "api/v1/product/user",
        data: {
          quantity: data.quantity,
          productId: product.id,
        },
      });
    },
    onSuccess: (res) => {
      alert("신청이 완료되었습니다.");
      setCount(0);
    },
    onError: (e: { message: number; error: string }) => {
      alert(e?.error || "서버요청 에러");
    },
  });

  const handleSubmit = () => {
    if ((ownedCoin ?? 0) < product.price * count) {
      return alert("보유 토큰이 부족합니다.");
    }

    if (count < 1) {
      return alert("주문 개수를 1개 이상 입력해주세요.");
    }

    createProductUser({ productId: product.id, quantity: count });
  };

  return (
    <QnAItem key={product.id}>
      <QnAItemContent>
        <QnAItemTitle>
          {product.name}

          <QnAItemPrice>
            {formatPrice(product.price)} <span>토큰</span>
          </QnAItemPrice>
        </QnAItemTitle>

        <AnswerContent>
          주문개수{" "}
          <Input
            type="number"
            onChange={(e) => {
              setCount(parseInt(e.target.value));
            }}
            value={count === 0 ? "" : count}
            placeholder="0"
          />
        </AnswerContent>
      </QnAItemContent>
      <SubmitBox>
        <SubmitContent>
          주문금액
          <SubmitUnit>
            {formatPrice(product.price * count)} <span>토큰</span>
          </SubmitUnit>
        </SubmitContent>

        <Button onClick={handleSubmit} disabled={isLoading}>
          신청
        </Button>
      </SubmitBox>
    </QnAItem>
  );
};
